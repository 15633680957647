import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const OpeningOfANewEStore: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/opening-e-store.jpg)' }}
        ></div>
        <article className="post">
          <h1>Opening of a new e-store</h1>
          <p>
            Since our latest newsletter The Longines Canada corporate e-store
            has been launched in July 2021 in two languages:
          </p>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>COUNTRY</th>
                  <th>WEBSITE URL</th>
                  <th>LANGUAGE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-ca mr-2"></span> Canada
                  </td>
                  <td>
                    ww.longines.com/en-ca
                    <br />
                    www.longines.com/fr-ca
                  </td>
                  <td>
                    English
                    <br />
                    French
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>
            Longines will continue to rollout its corporate e-stores in several
            markets. Each portal will enable the end consumer to purchase our
            products directly online and to be delivered at the address of
            her/his choice.
          </p>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>COUNTRY</th>
                  <th>WEBSITE URL</th>
                  <th>LANGUAGE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-tw mr-2"></span> Taiwan
                  </td>
                  <td>www.longines.com/zh-tw</td>
                  <td>Chinese - Taiwan</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-mx mr-2"></span> Mexico*
                  </td>
                  <td>www.longines.com/es-mx</td>
                  <td>Spanish</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-ru mr-2"></span>Russia*
                  </td>
                  <td>www.longines.com/ru</td>
                  <td>Russian</td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-gr mr-2"></span>Greece
                  </td>
                  <td>
                    www.longines.com/gr-gr
                    <br />
                    www.longines.com/en-gr
                  </td>
                  <td>
                    Greek
                    <br />
                    English
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="flag-icon flag-icon-kr mr-2"></span>Korea
                  </td>
                  <td>www.longines.com/kr</td>
                  <td>Korean</td>
                </tr>
              </tbody>
            </table>
          </div>
          <small>* Switch from light to full e-com</small>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">
                      Go live of the portals planned from Q4 2021 to Q1 2022
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/blaise-virchaux.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:blaise.virchaux@longines.com">
                    blaise.virchaux@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default OpeningOfANewEStore

export const pageQuery = graphql`
  query OpeningOfANewEStore_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
